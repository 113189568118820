jQuery($ => {

	if( $('.block__employees').length ) {
		const options = {
			valueNames: [ 'name', 'job' ]
		};

		const employeeList = new List('employeeList', options);
		const input = document.getElementById('employeeSearch');

		input.addEventListener('keyup', (event) => employeeList.search(event.target.value));
		/* filtering employees */
		if( $('.person').length ) {
			function filter(filters) {
				const $listContainer = $('.people.list');
				$listContainer.find('.person').each((index, el) => {
					if( filters.length ) {
						let temp = filters.length;
						for( const f of filters ) {
							if( el.dataset[f.type].includes(f.value) ) {
								temp--;
							}
						}
						if( temp === 0 ) {
							$(el).show();
						} else {
							$(el).hide();
						}
					} else {
						$(el).show();
					}
				});
			}

			const categories = document.querySelectorAll('.filter .row .col a[data-filter]');

			if( categories.length ) {
				for( const cat of categories ) {
					cat.addEventListener('click', (event) => {
						if( event.target.classList.contains('selected') ) {
							event.target.classList.remove('selected');
						} else {
							categories.forEach((el) => {
								if( el.dataset.filterType === cat.dataset.filterType ) {
									el.classList.remove('selected');
								}
							});
							cat.classList.add('selected');
						}
						startFilter();
					});
				}
			}

			const startFilter = () => {
				let filters = [];
				[...categories].map((e) => {
					if( e.classList.contains('selected') ) {
						filters.push({
							value: e.dataset.filter,
							type: e.dataset.filterType
						});
					}
				});
				filter(filters);
			}
		}
		/* end filtering employees */


	}

});
