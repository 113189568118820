document.addEventListener('DOMContentLoaded', () => {
	window.dataLayer = window.dataLayer || [];
	function gtag() {
		dataLayer.push(arguments);
	}
	window.addEventListener('CookiebotOnAccept', function (e) {
		const consents = {  security_storage: 'granted' }; // necessary
		if (Cookiebot && Cookiebot.consent && Cookiebot.consent.necessary && gtag) {
			consents.functionality_storage = 'granted';
			consents.personalization_storage = 'granted';
		}
		if (Cookiebot && Cookiebot.consent && Cookiebot.consent.statistics && gtag) {
			consents.analytics_storage = 'granted';
		}
		if (Cookiebot && Cookiebot.consent && Cookiebot.consent.marketing && gtag) {
			consents.ad_personalization = 'granted';
			consents.ad_storage = 'granted';
			consents.ad_user_data = 'granted';
		}
		gtag('consent', 'update', consents);
	});
	window.gtag_enable_tcf_support = true;
});
