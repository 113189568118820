jQuery($ => {
	const opts = {
		backdrop: 'static'
	};
	const modal = new bootstrap.Modal(document.getElementById('searchModal'), opts);
	$('.nav-item-search').click(e => {
		e.preventDefault();
		modal.show();
	});

	const searchInput = document.getElementById('searchInput');
	const searchIcon = document.querySelector('.search-icon.search-action');

	searchInput.addEventListener('keypress', (e) => {
		if( e.code === 'Enter' && e.target.value.length > 2 ) {
			navigate(e.target.value);
		}
		return false;
	});

	searchIcon.addEventListener('click', (e) => {
		if( searchInput.value.length > 2 ) {
			navigate(searchInput.value);
		}
	})

	const navigate = (searchTerm) => {
		window.location.assign( `${fjellhaugSearchPageUrl}?q=${searchTerm}` );
	}
});
