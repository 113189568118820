jQuery($ => {
	$('.news-carousel .owl-carousel').not('.news-carousel.more-items .owl-carousel').owlCarousel({
		loop: false,
		responsive: {
			0: {
				items: 1.2
			},
			768: {
				items: 1.6
			}
		}
	});
	$('.news-carousel.more-items .owl-carousel').owlCarousel({
		loop: true,
		responsive: {
			0: {
				items: 1.5
			},
			768: {
				items: 2.5
			}
		}
	});
	$('.news-carousel .news-image .placeholder-wrapper').click(function(){
		$(this).addClass('d-none').next('.video-wrapper').removeClass('d-none');
		const $video = $(this).next('.video-wrapper').find('video');
		if( $video[0] ) {
			$video[0].play();
		}
	});
});
