jQuery($ => {
	// Helper for creating Boostrap tables inside Redactor WYSIWYG content
	const $tables = $('table');
	$tables.map((index, el) => {
		const $this = $(el);
		$this.addClass('table table-striped');
		$this.find('thead tr th').map((index, el) => {
			el.setAttribute("scope", "col");
		});
		$this.find('tbody tr th').map((index, el) => {
			el.setAttribute("scope", "row");
		});
	})
});

